/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { createElement, Fragment }  from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

function SEO({ description, lang, meta, title, data, tagString }) {
    const metaDescription = data.seo.MetaDesc || description
    const metaTitle = data.seo.MetaTitle || title

    const metaTags = data.seo.tags
    // Tags for Testing
    // [{"tagName": "SiteColor","tagContent":"Blue","id":"\"SiteColorBlue\"","editState":false},
    // {"tagName": "DealerID","tagContent":"314","id":"\"DealerID314\"","editState":false}]
    let tags = []
    metaTags.forEach(tag => tags.push({ "name": tag.tagName, "content": tag.tagContent }));

    const arrTags = tagString.tags
    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    name: `title`,
                    content: metaTitle,
                }
            ].concat(meta).concat(tags)}
        >
            {arrTags.map(el => {
                var generatedEl;
                switch(el.nodeName.toLowerCase()) {
                    case "script":
                        if (el.src == "https://exo.autogenius.io/"){
                          return <script crossorigin={el.crossorigin} defer={el.defer} integrity={el.integrity} nomodule={el.nomodule} src={el.src} type={el.type} >{el.innerHTML}</script>
                        }
                        else{
                          return <script async={el.async} crossorigin={el.crossorigin} defer={el.defer} integrity={el.integrity} nomodule={el.nomodule} src={el.src} type={el.type} >{el.innerHTML}</script>
                        }
                    case "style":
                        return <style media={el.media} type={el.type}>{el.innerHTML}</style>
                    case "link":
                        return <link crossorigin={el.crossorigin} href={el.href} hreflang={el.hreflang} media={el.media} referrerpolicy={el.referrerpolicy} rel={el.rel} sizes={el.sizes} type={el.type} />
                    default:
                        break;
                }
                return (
                    generatedEl
                )
            })}
            <title>{metaTitle}</title>
        </Helmet>
    )
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
}

export default SEO
